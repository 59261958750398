<template>
  <div class="d-flex flex-column">
    <header v-if="stickyGroupKey" class="section-list-header p-3 m-0">
      <slot name="headerTemplate" :groupKey="stickyGroupKey"></slot>
    </header>
    <div class="section-list d-flex flex-grow-1 flex-column pretty-scroll-theme px-3 pt-4"
      style="overflow-y: auto; height: 0;" ref="list" @scroll="checkScroll()">
      <div class="section-list-group" v-for="(group, index) in groupedItems" :data-group-key="group[0]">
        <slot v-if="index > 0" name="itemHeaderTemplate" :groupKey="group[0]">{{ group[0] }}</slot>
        <template v-for="item in group[1]">
          <slot name="itemTemplate" :item="item">
            {{ item }}
          </slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UserPost } from "@/types";

export default defineComponent({
  name: "SectionList",
  data() {
    return {
      stickyGroupKey: null,
      scrolled: false,
      observer: null,
      intersectingGroups: [],
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    groupBy: {
      type: Function,
      required: true,
    },
    uniqueGroups: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    items: {
      handler() {
        if (!this.scrolled) {
          this.$nextTick(function () {
            this.scrollChatBottom();
          });
        }
      }, deep: true,
    },
    groupedItems: {
      handler(newGroups, oldGroups) {
        let added: Array<UserPost | number>;
        if (this.uniqueGroups)
          added = [...newGroups.entries()].filter(group => ![...oldGroups.entries()].includes(group));
        else
          added = newGroups.filter(group => !oldGroups.includes(group));
        this.observeAddedGroups(added);
      }, deep: true,
    },
    intersectingGroups: {
      handler() {
        this.stickyGroupKey = [...this.intersectingGroups].sort((el1, el2) => {
          return el1.getBoundingClientRect().top - el2.getBoundingClientRect().top
        })[0].dataset.groupKey;
      }, deep: true,
    },
  },
  methods: {
    observeAddedGroups(added: any) {
      this.$nextTick(function () {
        for (let group of added) {
          let elements = null;
          if (group[0] === null) {
            elements = document.querySelectorAll(`[data-group-key]`);
          } else {
            elements = document.querySelectorAll(`[data-group-key="${group[0]}"]`);
          }
          for (const element of elements) {
            this.observer.observe(element);
          }
        }
      });
    },
    checkScroll() {
      const d = $(this.$refs.list);
      this.scrolled = (d.scrollTop()) < (d.prop("scrollHeight") - d.prop("offsetHeight"));
    },
    checkIntersection(entries: IntersectionObserverEntry[]) {
      for (let entry of entries) {
        const root = this.$refs.list;
        if (!root) break;
        if (entry.isIntersecting) {
          this.intersectingGroups.push(entry.target);
        } else {
          let i = this.intersectingGroups.indexOf(entry.target);
          if (i >= 0) this.intersectingGroups.splice(i, 1);
        }
      }
    },
    scrollChatBottom() {
      const d = $(this.$refs.list);
      d.scrollTop(d.prop("scrollHeight"));
    },
  },
  computed: {
    groupedItems() {
      if (this.uniqueGroups)
        return this.items.reduce((group: any, item: UserPost) => {
          const groupKey = this.groupBy(item);
          if (group.has(groupKey)) {
            group.get(groupKey).push(item);
          } else {
            group.set(groupKey, [item]);
          }
          return group;
        }, new Map());
      else
        return this.items.reduce((group: any, item: UserPost) => {
          const groupKey = this.groupBy(item);
          if (group.length > 0 && group[group.length - 1][0] === groupKey) {
            group[group.length - 1][1].push(item);
          } else {
            group.push([groupKey, [item]]);
          }
          return group;
        }, []);
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(this.checkIntersection, {
      root: this.$refs.list,
      rootMargin: "0px",
      threshold: 0.0,
    })

    if (this.items.length > 0) this.stickyGroupKey = this.groupedItems[0][0];

    this.$nextTick(function () {
      this.observeAddedGroups(this.groupedItems);
      this.scrollChatBottom();
    });
  }
});
</script>
