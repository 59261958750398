<template>
  <div class="toast-container my-3" :data-hplacement="horizontalPlacement" :data-vplacement="verticalPlacement">
    <TransitionGroup tag="div" name="list" class="toast-list">
      <Toast v-for="toast in toasts" :key="toast.id" :header="toast.header" :body="toast.body" :classes="toast.classes"
        @close="hideToast(toast)" />
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import Toast from "./Toast.vue";
import { usePresentationStore } from "@/entrypoints/stores/presentation";

export default defineComponent({
  name: "ToastManager",
  components: { Toast },
  props: {
    placement: {
      type: String,
      default: () => "bottom left",
    },
  },
  computed: {
    ...mapState(usePresentationStore, ["toasts"]),
    horizontalPlacement() {
      let match = this.placement.match(/(top|start|center|bottom|end) (left|start|center|right|end)/);
      return match ? match[2] : "left";
    },
    verticalPlacement() {
      let match = this.placement.match(/(top|start|center|bottom|end) (left|start|center|right|end)/);
      return match ? match[1] : "top";
    },
  },
  methods: {
    ...mapActions(usePresentationStore, ["hideToast"]),
  },
});
</script>

<style>
.toast-container {
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.toast-container[data-hplacement=left],
.toast-container[data-hplacement=start] {
  align-items: flex-start;
}

.toast-container[data-hplacement=center] {
  align-items: center;
}

.toast-container[data-hplacement=right],
.toast-container[data-hplacement=end] {
  align-items: flex-end;
}

.toast-container[data-vplacement=top],
.toast-container[data-vplacement=start] {
  justify-content: flex-start;
}

.toast-container[data-vplacement=center] {
  justify-content: center;
}

.toast-container[data-vplacement=bottom],
.toast-container[data-vplacement=end] {
  justify-content: flex-end;
}

.toast-list {
  min-width: 350px;
  pointer-events: auto;
}

.list-item {
  transition: all 0.5s ease-in;
  opacity: 1 !important;
  z-index: 5;
  background-color: white !important;
}

.list-enter,
.list-leave-to {
  opacity: 0 !important;
  transform: scale(1.1);
}

.list-leave-active {
  z-index: 4;
}
</style>
